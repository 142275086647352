@font-face {
  font-family: "Telegraf Ultralight";
  src: url("./fonts/Telegraf\ UltraLight\ 200.otf");
}
@font-face {
  font-family: "Telegraf Ultrabold";
  src: url("./fonts/Telegraf\ UltraBold\ 800.otf");
}
@font-face {
  font-family: "Telegraf Regular";
  src: url("./fonts/Telegraf\ Regular\ 400.otf");
}

:root {
  --primary-font: "Telegraf Ultralight";
  --secondary-font: "Telegraf Ultrabold";
  --terciary-font: "Telegraf Regular";
  --primary-color: #a31c46;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(148, 147, 147, 0.164);
  padding: 0;
}

/*navBar Styles*/

.link {
  text-decoration: none;
  color: white !important;
  padding-right: 3vw;
  font-size: 1.5vw;
  font-family: var(--secondary-font);
  margin-top: 1rem;
}
.link:hover {
  color: var(--primary-color) !important;
}
a {
  text-decoration: none !important;
  color: var(--primary-color) !important;
}

/*nosotros*/

.img-equipo {
  width: 100%;
}
.nuestros-numeros {
  background-image: url('./image/madera.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4rem;
  font-family: var(--secondary-font);
  text-transform: uppercase;
}
.iconos-valores {
  font-family: var(--secondary-font);
  text-transform: uppercase;
}
.nuestros-numeros h2 {
  color: white;
  text-align: center;
}
.container-numeros {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3rem;
}
.container-iconos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3rem;
}
.info-text {
  margin-top: 8%;
  width: 100%;
}
.contenedor-textoE {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.text-equipo {
  margin-top: 5vh;
  height: 50%;
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.contImg-valores {
  width: 50%;
  display: flex;
}
.texto-valores {
  display: flex;
  flex-direction: column;
  width: 40%;
  font-family: var(--primary-font);
}
.texto-valores h2 {
  text-align: center;
}
.texto-valores p {
  margin-bottom: 3rem;
}
.img-valores {
  width: 48vw;
  height: 35vw;
  margin-left: 0.5vw;
}
.text-nosotros-t {
  font-size: 0.8rem;
  text-align: justify;
}
@media (max-width: 699px) {
  .text-nosotros-t {
    font-size: 0.7rem;
    text-align: justify;    
    width: 120%;
    margin-left: -1rem;
  }
  .contenedor-tercero {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-valores {
    width: 58vw;
    height: 45vw;
    margin-left: -3vw;
  }
}
@media (max-width: 999px) {
  .text-nosotros-t {
    font-size: 0.7rem;
    text-align: justify;
  }
  .contenedor-tercero {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1360px) {
  .text-nosotros-t {
    font-size: 0.8rem;
    text-align: justify;
  }
  .contenedor-tercero {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contenedor-tercero h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.content-ultimo {
  display: flex;
  height: 80%;
  width: 100%;
  margin-top: 10vh;
  margin-bottom: 10%;
}
.imagenU {
  width: 25%;
  height: 55%;
  margin-top: 20%;
  margin-left: 5%;
  margin-right: 2%;
}
.textFinal {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  margin-left: 4%;
  margin-top: 6%;
}
.ultimo-text {
  font-size: 1rem;
  text-align: justify;
  width: 90%;
}
.imagenD {
  width: 25%;
  height: 55%;
}
.imagenC {
  width: 15%;
  height: 15%;
}
.imagenE {
  width: 15%;
  height: 15%;
}
.relleno {
  background-color: #1e6554;
  height: 20vh;
  width: 15vw;
}
@media (max-width: 640px) {
  .iconos-valores .container-iconos {
    font-family: var(--secondary-font);
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
  }
  .imagenD {
    width: 15%;
    height: 15%;
  }
  .text-secundario {
    font-size: 0.8rem !important;
    text-align: center !important;
    color: rgb(255, 255, 255) !important;
    font-weight: bold;
  }
  .texto-primeroT {
    color: black;
    filter: drop-shadow(3px 5px 20px rgb(255, 255, 255));
    font-weight: bold;
    font-size: 2.5rem !important;
    text-align: center;
  }
}
.texto-primeroT {
  color: black;
  filter: drop-shadow(3px 5px 20px rgb(255, 255, 255));
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}
.contenedor-tercero {
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 100%;
  display: flex;
}
.text-secundario {
  font-size: 1.5rem;
  text-align: center;
  color: white;
  font-weight: bold;
}
.text-icons {
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
}

.texto-equipo {
  position: relative;
  top: 10vw;
  font-size: 2vw;
  padding-left: 6.5%;
  color: white;
}
.camion-img {
  width: 100%;
  height: 58vh;
}
.text {
  height: 30vh;
  padding-bottom: 4vh;
  padding-top: 4vh;
  font-size: 180%;
}
/*pag 2*/



.textProductos {
  font-size: 4vw;
  padding-top: 4vh;
  margin: 0;
  margin-block-end: 0 !important;
}
.parafoProducto {
  width: 40vw;
}
.imgProduct {
  display: flex;
}
.pUno {
  height: 40vh;
  width: 25vw;
  background-image: url("./image/produno.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.pDos {
  height: 40vh;
  width: 17vw;
  background-image: url("./image/productDos.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* HOME*/

.imageCamion {
  width: 100%;
}
.introHome {
  padding: 1rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  margin-left: 1rem;
  font-family: var(--primary-font);
  font-size: 2vw;
  width: 85vw;
}
.introHome2 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: 3rem !important;
  font-family: var(--primary-font);
  font-size: 1.5vw;
  width: 85vw;
}
@media (max-width: 750px) {
  .introHome {
    margin-top: 6rem;
    margin-bottom: 6rem;
    margin-right: 3rem !important;
    font-family: var(--primary-font);
    font-size: 1rem;
    width: 85vw;
  }
  
  .textNuestrosProductos {
    font-family: var(--terciary-font);
    padding: 1rem;
    margin-bottom: 4rem;
  }
  .clientes .clientesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .clienteItem {
    margin-bottom: -9rem !important;
  }
  .clienteOpinion {
    font-family: var(--terciary-font);
    text-align: center;
    font-size: 0.8rem !important;
  }
  
}
.containerIntroHome {
  background-color: rgba(148, 147, 147, 0.164);
  padding: 3rem;
}

/* Carrusel */

.carruselCaption {
  display: flex;
  flex-direction: column;
  top: 23vw;
  color: #fff;
  text-align: center;
}
.textCaption {
  font-size: 4vw;
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--secondary-font);
}
.textCaption2 {
  font-size: 2vw;
  font-weight: bold;
  background-size: 100% 42%;
  background-repeat: no-repeat;
  background-position: 0 80%;
  color: rgb(255, 255, 255);
  padding: 0.2rem;
  text-transform: uppercase;
}
.imgCarrusel {
  filter: brightness(0.8) contrast(110%);
}

/* Productos */

.nuestrosProductos {
  font-family: var(--secondary-font);
  padding: 1rem;
  margin-top: 6rem;
  text-align: center;
  font-size: 2rem;
}
.textNuestrosProductos {
  font-family: var(--terciary-font);
  padding: 1rem;
  margin-bottom: 4rem;
  font-size: 1rem;
}
.productos .galleryContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
  grid-gap: 10px;
}
.galleryImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.galleryItem:nth-child(1) {
  grid-column-start: span 2;
  grid-row-start: span 2;
}

.galleryItem:nth-child(2) {
  grid-column-start: span 1;
  grid-row-start: span 1;
}
.galleryItem:nth-child(5) {
  grid-column-start: span 1;
  grid-row-start: span 1;
}
.galleryItem {
  position: relative;
}
.galleryTitle {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 10px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.5s;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: bold;
  font-size: 3vw;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.galleryTitle:hover {
  color: white;
  background: #a31c4762;
  transition: background 0.3s ease-in;
}
.galleryImg {
  filter: blur(0px);
}
.galleryItem:hover .galleryTitle {
  opacity: 1;
}

.galleryItem:hover .galleryImg {
  filter: blur(1px);
  transition: filter 0.3s ease-in;
}

/* for touch screen devices */

@media (hover: none) {
  .location-title {
    opacity: 1;
  }
  .location-image img {
    filter: blur(2px);
  }
}

/*  CLIENTES  */

.clientes .clientesContainer {
  display: flex;
}
.clienteItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  height: 60vh;
  padding-top: 2rem;
}
.clienteImg {
  width: 100px;
  height: 100px;
  border-radius: 3rem;
  object-fit: cover;
  border-radius: 50%;
}
.clienteTitle {
  font-family: var(--secondary-font);
  color: var(--primary-color);
  padding-top: 1rem;
  font-size: 1.5vw;
}
.clienteOpinion {
  font-family: var(--terciary-font);
  text-align: center;
  font-size: 1.2rem;
}
.titleNumeros {
  padding: 2rem;
}
.btnConocenos {
  background-color: black;
  color: white;
  font-family: var(--primary-font);
  padding: 0.5rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.btnConocenosContainer {
  font-family: var(--primary-font);
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
}
.btnConocenos:hover {
  color: var(--primary-color);
  font-weight: bold;
}
.btnConocenos2:hover {
  color: var(--primary-color);
  font-weight: bold;
}

/* TABLAS */

.containerTablas {
  display: flex;
}
.containerTextTablas {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imgTablas {
  width: 50vw;
  height: 100vh;
}
@media (max-width: 759px) {
  .imgTablas {
    display: none;
  }
  .containerTablas {
    background-image: url("./image/_NPH47682.webp");
    background-size: cover;
    width: 100vw;
    height: 150vh;
  }
  .nuestrosProductos2 {
    font-family: var(--secondary-font);
    color: white;
    padding: 1rem;
    margin-top: 20vh;
    font-size: .5vh;
    margin-left: 1rem !important;
  }
  .parrafoTablas {
    font-family: var(--terciary-font);
    font-weight: bold;
    font-size: 1vw;
    padding: 1rem;
    color: white !important;
    width: 100%;
    margin-left: 2rem;
  }
  .btnConocenos2 {
    margin-bottom: 12vh;
  }
  .btnConocenos {    
  margin-top: 3rem;
  }
}
.parrafoTablas {
  font-family: var(--terciary-font);
  font-weight: bold;
  font-size: 1.3rem;
  padding: 1rem;
  color: var(--primary-color);
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 6rem;
  margin-left: 2rem;
}
.nuestrosProductos2 {
  font-family: var(--secondary-font);
  padding: 1rem;
  margin-bottom: 6rem;
  font-size: 2rem;
  margin-left: 2rem;
}
.btnConocenos2 {
  background-color: black;
  color: white;
  font-family: var(--primary-font);
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

/* FORMULARIO */

.formularioContainer {
  background-image: url("./image/_NPH4879.webp");
  background-size: cover;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formularioContainer2 {
  background-image: url("./image/_NPH4713.webp");
  background-size: cover;
  margin-top: 6rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-bottom: 3rem;
  padding: 2rem;
  font-family: var(--secondary-font);
  color: white;
}
.btnEnviado {
  background-color: black;
  color: white;
  font-family: var(--primary-font);
  padding: .5rem;
  margin-bottom: 3rem;
  border-radius: 0.3rem;
  margin-left: 2rem;  
  align-items: center;
}
.contenedorBtn {
  display: flex;
  justify-content: center;
}

.titleEnviado {
  font-family: var(--secondary-font);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 7rem;
  font-size: 3vw;
  margin-left: 2rem;
  text-align: center;
}
@media (max-width: 759px) {
  form {
    width: 70vw;
  }  
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 5vw;
  }  
}
input {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: 1px solid #a31c46;
}
.inputMsj {
  padding: 1rem;
  margin-left: 0;
}
label {
  margin-bottom: 1rem;
}
textarea {
  background-color: rgba(255, 255, 255, 0.253);
  color: #000000;
}
.btnSend {
  background-color: var(--primary-color);
  color: white;
  font-family: var(--primary-font);
  padding: 0.5rem;
  margin-top: 2rem;
  width: 20%;
}
.consultaTitle {
  font-family: var(--secondary-font);
  color: white;
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  font-size: 2.5rem;
  margin-left: 2rem;
}
.consultaTitle2 {
  font-family: var(--secondary-font);
  color: white;
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  font-size: 3vw;
  margin-left: 2rem;
  text-align: center;
  width: 60%;
}
@media (max-width: 759px) {
  .consultaTitle2 {
    font-size: 6vw;
  }
}

@media (max-width: 1088px) {
  .btnSend {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--primary-font);
    padding: 0.5rem;
    margin-top: 2rem;
    width: 30%;
  }
}
@media (max-width: 780px) {
  .btnSend {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--primary-font);
    padding: 0.5rem;
    margin-top: 2rem;
    width: 40%;
  }
}
@media (max-width: 620px) {
  .btnSend {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--primary-font);
    padding: 0.5rem;
    margin-top: 2rem;
    width: 50%;
  }
}

/* Footer */

.footerContainer {
  background-color: rgb(0, 0, 0);
  color: var(--primary-color);
  font-family: var(--terciary-font);
  padding: 3rem;
}
.containerIconosFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.correoIcon {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  margin-left: 4rem;
}
.logoCellphone {
  margin-left: 2rem;
}
.logoReloj {
  margin-left: 3rem;
}
.derechosReservados {
  text-align: center;
  margin-top: 2rem;
  color: white;
}
footer .firma {
  text-transform: none;
  color: #00fe7f95 !important;
  font-size: .7rem;
  text-decoration: none;
}
footer .firma:hover {
  color: #00fe7f !important;
}
.text-footer2 {
  text-align: center;
  margin-top: 1rem;
  font-size: .7rem;
  color: rgb(110, 110, 110);
}
footer a:hover {
color: white !important;
}

@media (max-width: 750px) {
  .containerIconosFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logoLosAndes {
    width: 30;
  }
  .logoCellphone {
    margin-left: 1rem;
  }
  .correoIcon {
    margin-left: 5rem;
  }
  .email {
    margin-left: 4rem;
  }
  .logoReloj {
    margin-left: 2rem;
  }
  .horarios {
    margin-left: 1rem;
  }

  .footerContainer {
    font-size: small;
    width: 100%;
  }
  .logoFb {
    margin-left: -1rem;
  }
  
}

/* Home */

.titleHome {
  text-align: center;
  padding-bottom: 2rem;
}

/* Contacto */

.titleContacto {
  font-family: var(--secondary-font);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
  font-size: 3rem;
  margin-left: 2rem;
  text-align: center;
}
.titleContacto2 {
  font-family: var(--secondary-font);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
  font-size: 3rem;
  margin-left: 2rem;
  text-align: center;
}
.direccion {
  color: var(--primary-color);
}
.mapsContacto {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5rem;
}
@media (max-width: 759px) {
  .mapsContacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .textMaps {
    margin-top: 2rem;
  }
}
.textMaps {
  font-family: var(--terciary-font);
  color: #1e6554;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*medias*/

@media (max-width: 768px) {
  .link {
    text-decoration: none;
    color: white;
    padding-right: 3vw;

    font-size: 2.5vw;
  }
}
@media (max-width: 425px) {
  .link {
    text-decoration: none;
    color: white;
    padding-right: 3vw;
    font-size: 4vw;
  }
}
